import { FooterLinkType } from '@/types/Contentful/Footer'

export const FOOTER_DISCLOSURE_LINKS: FooterLinkType[] = [
  {
    displayText: 'Privacy Policy',
    url: '/privacy',
    newTab: false,
    clickable: true,
    ariaLabel: 'Privacy Policy',
  },
  {
    displayText: 'Terms & Conditions',
    url: '/terms-of-use',
    newTab: false,
    clickable: true,
    ariaLabel: 'Terms & Conditions',
  },
  {
    displayText: 'Ad Choices',
    url: '/privacy',
    newTab: false,
    clickable: true,
    ariaLabel: 'Ad Choices',
  },
  {
    displayText: 'Do Not Sell or Share My Personal Information',
    url: '/ccpa-addendum',
    newTab: false,
    clickable: true,
    ariaLabel: 'Do Not Sell or Share My Personal Information',
  },
]
