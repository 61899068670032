import React, { useState } from 'react'
import dynamic from 'next/dynamic'
import { STORE_PHONE } from '@/data/constants/STORE_PHONE'
import { OPERATING_HOURS } from '@/data/constants/OPERATING_HOURS'
import type { FooterDeploymentType } from '@/types/Contentful/Footer'
import { FOOTER_DISCLOSURE_LINKS } from '@/data/constants/FOOTER_DISCLOSURE_LINKS'

const Row = dynamic(import('@csc/dls/Row'))
const Col = dynamic(import('@csc/dls/Col'))
const PageContent = dynamic(import('@csc/dls/PageContent'))
const Link = dynamic(import('@csc/dls/Link'))
const Text = dynamic(import('@csc/dls/Text'))
const IconRenderer = dynamic(import('@csc/dls/IconRenderer'))
const Instagram = dynamic(import('@csc/dls/Icons/Instagram'))
const Tiktok = dynamic(import('@csc/dls/Icons/Tiktok'))
const Youtube = dynamic(import('@csc/dls/Icons/Youtube'))
const Facebook = dynamic(import('@csc/dls/Icons/Facebook'))
const Twitter = dynamic(import('@csc/dls/Icons/Twitter'))

const Subscription = dynamic(import('@/components/Sitewide/Footer/Subscription'))
const AccessibilityModal = dynamic(import('@/components/Sitewide/Footer/AccessibilityModal'))
const FooterAccordions = dynamic(import('@/components/Sitewide/Footer/FooterAccordions'))

const defaultCopyWriteNotice = (
  'One Kings Lane. All rights reserved. One Kings Lane and the elephant logo are registered trademarks of OKL Holdings, Inc.'
)
const year = (new Date()).getFullYear()
const Footer: React.FC<{
  maybeFooterFromContentful?: FooterDeploymentType | null
}> = ({
  maybeFooterFromContentful,
}) => {
  const [accessibilityModal, setAccessibilityModal] = useState<boolean>(false)
  return (
    <footer className="mt-8" role="contentinfo">
      <PageContent id="Footer Content">
        <Row cols={8} gap="xl">
          <Col span={{ sm: 8, md: 8, lg: 2 }} className="lg:order-last">
            <Subscription />
          </Col>
          <Col span={{ sm: 8, md: 8, lg: 4 }}>
            <FooterAccordions
              footerSections={maybeFooterFromContentful?.columns}
            />
          </Col>

          <Col span={{ sm: 8, md: 8, lg: 2 }} className="px-4 lg:px-0">
            <Text size="xl" bold>Contact Us</Text>
            <Link className="my-2" href={`tel:${STORE_PHONE}`}><Text>{STORE_PHONE}</Text></Link>
            {OPERATING_HOURS.map((statement) => (
              <Text key={statement} className="my-2">{statement}</Text>
            ))}
            <div className="flex gap-x-4 justify-center lg:justify-start mt-4">
              <Link aria-label="Instagram" href="https://www.instagram.com/onekingslane/">
                <IconRenderer className="mt-0.5 mr-2" IconComponent={Instagram} />
              </Link>
              <Link aria-label="Tiktok" href="https://www.tiktok.com/@onekingslane">
                <IconRenderer className="mt-0.5 mr-2" IconComponent={Tiktok} />
              </Link>
              <Link aria-label="Youtube" href="https://www.youtube.com/channel/UCt3uR2lQGK3EjY6U0gntfCw">
                <IconRenderer className="mt-0.5 mr-2" IconComponent={Youtube} />
              </Link>
              <Link aria-label="Facebook" href="https://www.facebook.com/OneKingsLane/">
                <IconRenderer className="mt-0.5 mr-2" IconComponent={Facebook} />
              </Link>
              <Link aria-label="Twitter" href="https://twitter.com/onekingslane">
                <IconRenderer className="mt-0.5 mr-2" IconComponent={Twitter} />
              </Link>
            </div>
          </Col>
        </Row>
        <Text size="sm" className="my-8 text-center">
          &copy;
          {year}
          {' '}
          {maybeFooterFromContentful?.copyrightNotice || defaultCopyWriteNotice}
        </Text>
        <div className="flex flex-wrap gap-x-4 justify-center my-8">
          {
            (maybeFooterFromContentful?.disclosureLinks || FOOTER_DISCLOSURE_LINKS).map((link) => (
              <div
                className="flex flex-wrap gap-x-4 justify-center my-8"
                key={link.displayText}
              >
                <Link
                  aria-label={link.ariaLabel || link.displayText}
                  className="no-underline my-2"
                  href={link.url}
                >
                  <Text size="sm">{link.displayText}</Text>
                </Link>
                <Text className="mx-3 lg:mx-8 my-2">|</Text>
              </div>
            ))
          }
          <button type="button" aria-label="Accessibility" className="my-2" onClick={() => setAccessibilityModal(true)}>
            <Text size="sm">Accessibility</Text>
          </button>
        </div>
      </PageContent>
      <AccessibilityModal
        open={accessibilityModal}
        onClose={() => setAccessibilityModal(false)}
      />
    </footer>
  )
}

export default Footer
